import request from '@/utils/request'

// 查询所有菜单
export function menu() {
    return request({
        url: '/menu',
        method: 'GET'
    })
}

// 获取一级目录
export function homemenu() {
    return request({
        url: '/menu/listInitMenu',
        method: 'GET'
    })
}
// 根据id获取菜单列表
export function menuid(mid) {
    return request({
        url: `/menu/${mid}`,
        method: 'GET',
    })
}
// 根据id获取详情
export function productid(pid) {
    return request({
        url: `/product/${pid}`,
        method: 'GET',
    })
}
// 根基分类id查询分类
export function listInitMenu(typeCode, category) {
    return request({
        url: `/menu/listInitMenu/${typeCode}/${category}`,
        method: 'POST',
    })
}
// 根据产品名模糊查询
export function searchProductByName(data) {
    return request({
        url: "/product/searchProductByName",
        method: "POST",

    })
}

// 获取应用领域分类
export function getType() {
    return request({
        url: '/menu/getType',
        method: 'GET',
    })
}

// 获取分类下子分类
export function getTypeList(category, data) {
    return request({
        url: `/menu/listInitMenu/${category}`,
        method: "POST",
        data
    })
}

// 获取顶部分类数据
export function getTopType(params) {
    return request({
        url: '/menu/getTopType',
        method: 'GET',
        params
    })
}


// 根据id获取菜单
export function genMenuInfo(mid) {
    return request({
        url: `/menu/${mid}`,
        method: 'GET'
    })
}

// 品牌介绍
export function pinpai(mid) {
    return request({
        url: `/menu/one/${mid}`,
        method: 'GET'
    })
}

// 提交反馈信息
export function submitFeedback(data) {
    return request({
        Accept: 'json',
        url: '/user/problem',
        method: 'POST',
        data
    })
}

// 注册
export function userRegistered(data) {
    return request({
        Accept: 'json',
        url: '/authorization/registered',
        method: 'POST',
        data
    })
}

// 检测用户名是否可用
export function checkUserName(data) {
    return request({
        url: '/authorization/usernameIsAvailable',
        method: 'POST',
        data
    })
}

// 用户登录
export function userLogin1(data) {
    return request({
        Accept: 'json',
        url: '/authorization/login',
        method: 'POST',
        data
    })
}

// 发送验证码
export function getCheckCode(data) {
    return request({
        Application: 'json',
        url: '/authorization/messCode',
        method: 'POST',
        data
    })
}

// 用户是否的登录
export function checkUser() {
    return request({
        url: '/user/certification',
        method: 'GET'
    })
}

// 发送文件到邮箱
export function toFileInEmail(params) {
    return request({
        url: '/user/sendEmail',
        method: 'GET',
        params
    })
}

// 返回邮箱
export function getUserEmail() {
    return request({
        url: '/user/returnEmail',
        method: "GET"
    })
}

// 获取个人信息
export function getuserInfo(data) {
    return request({
        Accept: 'json',
        url: '/authorization/information',
        method: "POST",
        data
    })
}


// 视频观看次数
export function videoNum(id) {
    return request({
        url: `/user/watchNumber/${id}`,
        method: "POST"
    })
}

// 搜索主题或主讲人
export function searchBYKey(mid, params) {
    return request({
        url: `menu/${mid}/search`,
        method: 'GET',
        params
    })
}

// 资料中心表单提交
export function postMess(data) {
    return request({
        Accept: 'json',
        url: `/leave/mess`,
        method: 'post',
        data
    })
}