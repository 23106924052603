// 线上地址
const baseURL = 'https://thermo-minisite.synconize.com/minisite'

// 测试地址
// const baseURL = 'http://minisite-test.wrbt.synconize.com/test/'

// 线下地址
// const baseURL = 'http://106.14.218.186:7793/';

export default baseURL;
